import { OrganizationService } from './services/Organization/organization.service';

import { Deferred } from '../../../smarttask-common/src/lib/services/AngularSpecific/Deferred';
import { AuthService } from './services/auth.service';
import { ActivatedRouteSnapshot, NavigationEnd, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { Globals } from '@smarttask-common/src/lib/services/globals';

export function authenticate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authService = inject(AuthService);
    return authService.authenticate();
}

export const homeResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const organizationService = inject(OrganizationService);
    const globals = inject(Globals);

    let deferred = new Deferred<any>();
    let auto_redirect = route.queryParamMap.get('auto_redirect');

    //Had to read from location hrefs as home route doesn't have organization_id param.
    //Even if we add as a route param in this state it won't help
    let organization_id = getOrganizationId(location.href);
    if (auto_redirect || !organization_id) {
        if (auto_redirect) {
            globals.auto_redirect = true;
        }
        let no_companies = organizationService.goToDefaultOrganization('from_home_resolve');
        if (no_companies) {
            // setTimeout(() => {
            //     $state.go('welcome');
            // });
        }
        deferred.reject();
    }
    deferred.resolve();
    return deferred.promise;
};

function getOrganizationId(url: string): string | null {
    const regex = /\/o\/(\d+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
}
